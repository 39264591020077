import { useDispatch, useSelector } from 'react-redux';

import { toggleCompareProduct, setViewOffers } from '../compare/compare-actions';
const EMPTY_OBJECT = {};
/**
 * @typedef {Object} CompareFunctions
 * @property {function(): promise} toggleCompareProduct - Toggles product for compare
 * @property {function(): Boolean} isSelected - Checks even given product SKU is being compared
 */

/**
 * Returns redux connected compare functions and state
 * @name useProductCompare
 * @returns {CompareFunctions}
 */
export default function useProductCompare(stateless) {
    const dispatch = useDispatch();
    const compareProducts = useSelector(state => (!stateless ? state.compare.products : null));
    const offers = useSelector(state => (!stateless ? state.compare.offers : null));
    if (stateless) {
        return EMPTY_OBJECT;
    }
    return {
        toggleCompareProduct: (product, isV2) => dispatch(toggleCompareProduct(product, isV2)),
        isSelected: sku => (compareProducts || []).filter(p => p.sku === sku).length > 0,
        setViewOffers: sku => dispatch(setViewOffers(sku)),
        isViewOffersOpen: sku => sku in offers,
    };
}

export const useOffersView = product => {
    const dispatch = useDispatch();
    const isViewOffersOpen = useSelector(state => state.compare.offers[product?.sku]);
    return {
        setViewOffers: sku => dispatch(setViewOffers(sku)),
        isViewOffersOpen,
    };
};
