import React, { useState } from 'react';
import { Typography, Carousel, AttachableProductTile, Violator, AttachableProductTileSkeleton } from '@hpstellar/core';
import { RecommendedProduct } from '@hpstellar/core/visId';

import useProductPrice from '../../hooks/useProductPrice';
import useProductImpressions from '../../hooks/useProductImpressionsV2';
import useCartState from '../../hooks/useCartState';
import { useAttachableProductProps } from '../../hooks/useProductProps';
import { Helpers } from '../../core/src/helpers';

import '../css/topseller.less';

export const isProductAvailable = (product, prices) => {
    const { salePrice, isOOS, priceFetchFailed } = prices[product.sku] || { priceFetchFailed: true };
    return salePrice !== '' && !isOOS && !priceFetchFailed;
};

/**
 * Filtered product list based on available products
 * @param {*} products
 * @param {*} prices
 */
export const getAvailableProducts = (products = [], prices) => {
    return products.filter(p => isProductAvailable(p, prices));
};

const AttachProductTile = ({
    product,
    price,
    className,
    blockCartFlyout,
    showAttachedIndicator,
    showOfferMessage,
    detachable = true,
    trackAddToCart,
    attachedSkus,
    xsellProdInfo,
    xsellMethod,
    list,
    position,
    titleTag,
    enablePageLoader,
    loading,
    linkTarget,
    buttonColor,
    priceProps,
    visId,
}) => {
    const [getAttachableProductProps] = useAttachableProductProps({
        enablePageLoader,
        blockCartFlyout,
        trackAddToCart,
    });
    const { cartId } = useCartState();
    const [added, setAdded] = useState(false);
    const attached = attachedSkus ? attachedSkus[product.sku] : added;

    const item = getAttachableProductProps(
        product,
        price,
        () => setAdded(true),
        { resizeImage: true, detachable, attached, linkTarget, priceProps },
        {
            xsellProdInfo,
            xsellMethod,
            list,
            position,
            cartId,
        },
    );

    if (loading) {
        return <AttachableProductTileSkeleton key={product?.sku} className={className} />;
    }

    if (visId) {
        const savings = item.price.regularPrice !== item.price.salePrice;

        const recommendedProductprops = {
            ...(item.violatorMsg && {
                ViolatorProps: { label: item.violatorMsg, backgroundColor: 'red', theme: 'light' },
            }),
        };

        return (
            <RecommendedProduct
                key={product.sku}
                className={className}
                purchaseBtnProps={{
                    ...item.buttonProps,
                    variation: buttonColor,
                    children: item.translations?.buttons?.attach,
                }}
                title={item.title}
                titleTypographyProps={{ tag: titleTag }}
                image={item.image}
                description={item.description}
                LinkProps={{
                    ...(item.link && {
                        to: item.link.to,
                        children: item.link.children,
                        target: item.link.target,
                    }),
                }}
                PriceProps={{
                    finalPrice: `${Helpers.formatCurrency(item.price.salePrice, '$', true)}`,
                    ...(savings && {
                        oldPrice: `${Helpers.formatCurrency(item.price.regularPrice, '$', true)}`,
                    }),
                    ...(savings && {
                        saveLabel: (
                            <span>
                                SAVE
                                {Helpers.formatCurrency(item.price.priceDifference, '$', true)}
                                {price?.discountPercent > 0 && (
                                    <>
                                        <span>{Math.round(price.discountPercent)}%</span>
                                        <span className="suffix">)</span>
                                    </>
                                )}
                            </span>
                        ),
                    }),
                }}
                translations={{ descriptionLinkText: 'See more' }}
                hideViewDetailsBtn
                {...recommendedProductprops}
            />
        );
    }

    return (
        <AttachableProductTile
            {...item}
            showAttachedIndicator={showAttachedIndicator}
            showOfferMessage={showOfferMessage}
            attached={attached}
            key={product.sku}
            className={className}
            titleTag={titleTag}
            buttonProps={{ ...item.buttonProps, color: buttonColor }}
        />
    );
};

const TopSeller = ({
    products,
    title,
    description,
    carouselSettings,
    className,
    productTileClassName,
    hideOOS = true,
    productPrices,
    titleVariant = 'display',
    titleTag = 'h2',
    blockCartFlyout = false,
    showAttachedIndicator = true,
    showOfferMessage = false,
    attachedSkus = {},
    enablePageLoader,
    detachable,
    buttonColor = 'primary',
    // metric data
    list,
    xsellProdInfo,
    xsellMethod,
    vanityUrl,
    componentKey,
    batchKey,
    impressionKey,
    productTitleTag = 'h3',
    linkTarget,
    specialOfferMessage,
    trackImpressions,
    disableImpressions,
    impressionGroupSize,
    priceProps,
    loading,
    visId,
}) => {
    const priceData = useProductPrice(productPrices ? null : products);
    const { prices } = productPrices ? { prices: productPrices } : priceData;

    const availableProducts = !loading && hideOOS ? getAvailableProducts(products, prices) : products;

    const productsWithAnalytics = useProductImpressions({
        products: disableImpressions ? null : availableProducts,
        list,
        vanityUrl,
        componentKey,
        batchKey,
        customPrices: productPrices,
        key: impressionKey,
        trackerKey: impressionKey,
        trackMetrics: trackImpressions,
        isEmpty: disableImpressions,
        impressionGroupSize,
        impressionKey,
        xsellMethod,
        xsellProdInfo,
    });
    let prds = Array.isArray(productsWithAnalytics) ? productsWithAnalytics : availableProducts;
    if (prds && prds.length > 0) {
        return (
            <div className={`topseller-section${className ? ' ' + className : ''}`}>
                <div className="topseller-title-section">
                    <Typography variant={titleVariant} tag={titleTag} className="stellarFix topseller-title">
                        {title}
                    </Typography>
                    {specialOfferMessage && (
                        <Violator variation="offers" value={specialOfferMessage} className="topseller-offer-message" />
                    )}
                    {description && (
                        <Typography
                            className="topseller-description"
                            dangerouslySetInnerHTML={Helpers.createMarkup(description)}
                        />
                    )}
                </div>
                <Carousel {...carouselSettings}>
                    {prds.map((prod, idx) => {
                        return (
                            <AttachProductTile
                                key={idx}
                                product={prod}
                                price={prices[prod.sku]}
                                className={productTileClassName}
                                blockCartFlyout={blockCartFlyout}
                                showAttachedIndicator={showAttachedIndicator}
                                showOfferMessage={showOfferMessage}
                                attachedSkus={attachedSkus}
                                detachable={detachable}
                                xsellProdInfo={xsellProdInfo}
                                list={list}
                                xsellMethod={xsellMethod}
                                position={(prod && prod.position) || idx + 1}
                                titleTag={productTitleTag}
                                enablePageLoader={enablePageLoader}
                                linkTarget={linkTarget}
                                buttonColor={buttonColor}
                                priceProps={priceProps}
                                visId={visId}
                                loading={loading}
                            />
                        );
                    })}
                </Carousel>
            </div>
        );
    }
    return null;
};

export default TopSeller;
