import getProductProps, { getAttachableProductProps } from '../product/get-product-props';
import useAddToCart from './useAddToCart';
import useDeviceInfo from './useDeviceInfo';
import useProductCompare from './useProductCompare';

const EMPTY_OBJ = {};

/**
 * @typedef {Object} AttachableProductTileOptions
 * @property {Boolean} enablePageLoader - Set to true if you want to trigger the full page loader during add to cart
 * @property {Boolean} blockCartFlyout - Set to true if you block the normal cart preview flyout after add to cart
 * @property {Object} hooks - Optionally provide you own hooks for dependency injection
 */

/**
 * Returns helper functions for generating product props for AttachableProductTiles GFE component
 * @name useAttachableProductProps
 * @param {AttachableProductTileOptions} options
 * @returns {cartInfo}
 */
export const useAttachableProductProps = (options = EMPTY_OBJ) => {
    const { enablePageLoader, blockCartFlyout, hooks, trackAddToCart = true } = options;
    const addToCart = useAddToCart(trackAddToCart, { enablePageLoader });
    const { device } = useDeviceInfo(['device']);
    let cartHooks;
    //if cart flyout is unwanted, remove hook from dependency injection
    if (blockCartFlyout) {
        const { launchCartFlyout, ...rest } = addToCart;
        cartHooks = rest;
    } else {
        cartHooks = addToCart;
    }

    return [
        (product, price, onAttach, options = EMPTY_OBJ, analyticsData, trackCustomMetric, showOOS) => {
            const { linkTarget = '_blank', ...restOptions } = options;
            //HPEDT-11055 - on mobile link should alway open on the same page
            const linkTargetValue = device === 'mobile' ? '_self' : linkTarget;
            return getAttachableProductProps(
                product,
                price,
                onAttach,
                hooks || { addToCart: cartHooks },
                { ...restOptions, linkTarget: linkTargetValue },
                analyticsData,
                trackCustomMetric,
                showOOS
            );
        },
    ];
};

export const useProductProps = (options = EMPTY_OBJ) => {
    const { enablePageLoader, blockCartFlyout, hooks, trackAddToCart = true } = options;
    const addToCart = useAddToCart(trackAddToCart, { enablePageLoader });
    const compare = useProductCompare();
    let cartHooks;
    //if cart flyout is unwanted, remove hook from dependency injection
    if (blockCartFlyout) {
        const { launchCartFlyout, ...rest } = addToCart;
        cartHooks = rest;
    } else {
        cartHooks = addToCart;
    }
    const productHooks = hooks || { addToCart: cartHooks, compare };
    return [
        (product, price, options = EMPTY_OBJ, analyticsData, trackCustomMetric, showOOS) => {
            return getProductProps(product, price, productHooks, options, analyticsData, trackCustomMetric);
        },
    ];
};
